.header {
    .navbar {
        padding: 0;
    }

    .sticky .navbar {
        padding: 0;
    }

    .dropdown-item {
        padding: 10px !important;
    }

    .border-left::before {
        content: "";
        position: relative;
        width: 2px;
        height: 4px;
        background-color: #282c34;
    }

    .date-picker {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,
            box-shadow .15s ease-in-out;
    }

    .profile-icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 2px;
    }

    .icon-showpass {
        position: absolute;
        color: #0d6efd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        right: 10px;
        top: 50px;
    }

    .icon-showpass-singup {
        position: absolute;
        color: #0d6efd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        right: 10px;
        top: 42px;
    }

    .eyeAction:hover {
        color: #0d6efd;
        filter: grayscale(80%);
        cursor: pointer;
    }

    .message-err {
        display: flex;
        color: #f44336;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }

    .message-err-signup {
        color: #f44336;
        font-size: 14px;
    }
}

.emenu-file-input {
    min-height: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    flex: 1 1;
    background: #f8f8f8;
    box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px;
    border: 1px solid rgb(216, 210, 222);
    position: relative;
    overflow: hidden;

    .hide {
        display: none;
    }

    .emenu-file-icon {
        text-align: center;
    }

    .image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .img {
        height: 90%;
        width: 100%;
    }

    .popupDishAdd {
        padding-top: 10px;
    }

}

.popup-edit-info-user {
    font-size: 16px;
    .line-height-55 {
        line-height: 54px;
    }
    .disabled {
        cursor: not-allowed;
    }
    .margin-5 {
        margin-top: 5px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .icon-showpass-edit {
        position: absolute;
        color: #0d6efd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        right: 20px;
        bottom: 20px;
    }
}

.create-sign-for-file {
    .webviewer {
        height: calc(100vh - 95px);
    }

    //upload your file
    .files input {
        font-size: 14px !important;
        outline: 2px dashed #92b0b3;
        outline-offset: -10px;
        -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
        transition: outline-offset .15s ease-in-out, background-color .15s linear;
        padding: 100px 20px 85px 40px;
        text-align: center !important;
        margin: 0;
        width: 100% !important;
    }

    .files input:focus {
        outline: 2px dashed #92b0b3;
        outline-offset: -10px;
        -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
        transition: outline-offset .15s ease-in-out, background-color .15s linear;
        border: 1px solid #92b0b3;
    }

    .files {
        position: relative
    }

    .files:after {
        pointer-events: none;
        position: absolute;
        top: 30px;
        left: 0;
        width: 50px;
        right: 0;
        height: 56px;
        content: "";
        background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
        display: block;
        margin: 0 auto;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .color input {
        background-color: #f1f1f1;
    }

    .files:before {
        position: absolute;
        bottom: 10px;
        left: 0;
        pointer-events: none;
        width: 100%;
        right: 0;
        height: 57px;
        content: " Hoặc kéo tệp vào đây để mở tệp ";
        display: block;
        margin: 0 auto;
        color: #2ea591;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
    }
}

#avatar {
    /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
    background-image: url('http://i.stack.imgur.com/Dj7eP.jpg');

    /* make a square container */
    width: 150px;
    height: 150px;

    /* fill the container, preserving aspect ratio, and cropping to fit */
    background-size: cover;

    /* center the image vertically and horizontally */
    background-position: top center;

    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 50%;
}